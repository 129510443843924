import React from 'react';

import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';

import MuiAlert, { AlertProps, Color } from '@material-ui/lab/Alert';

import './App.css';

import landing from './assets/images/landing.png';
import { CHAIN_ID, CHAIN_NAME, MAX_MINT_COUNT } from './env';
import { LoadingElement } from './components/utils';
import {
  connectMetamask,
  mintDropBear,
  useNetworkAddress,
  watchTransaction,
} from './utils/blockchain';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {
  const IS_SOLD_OUT = true;
  const { userWalletAddress, chainId } = useNetworkAddress();

  const [dropDownCount, setDropDownCount] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingMessage, setLoadingMessage] = React.useState('');

  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertType, setAlertType] = React.useState<Color>('warning');

  React.useEffect(() => {
    setIsLoading(false);
  }, [userWalletAddress, chainId]);

  const handleChange = (e: any) => {
    setDropDownCount(!!e && !!e.target && !!e.target.value ? e.target.value : 1);
  };

  const handleMintClick = async () => {
    setIsLoading(true);

    setAlertType('warning');
    if (chainId !== CHAIN_ID) {
      setAlertMessage(
        `You are not on the correct network. Please connect to the ${CHAIN_NAME}.`
      );
      setShowAlert(true);
      setIsLoading(false);
    } else if (dropDownCount < 1 || dropDownCount > MAX_MINT_COUNT) {
      setAlertMessage(
        'You must mint between 1 and 10.'
      );
      setShowAlert(true);
      setIsLoading(false);
    } else if (!userWalletAddress) {
      setAlertMessage('There needs to be a connected wallet.');
      setShowAlert(true);
      setIsLoading(false);
    } else {
      setLoadingMessage('Waiting for MetaMask approval...');

      const mintDropbBearTransaction = await mintDropBear(userWalletAddress, dropDownCount);
      console.log(mintDropbBearTransaction);
      setLoadingMessage('Waiting for block confirmations...');

      const { message, result, transactionDetails } = mintDropbBearTransaction;
      console.log(transactionDetails);

      if (result) {
        // Pass transactiion to be watched
        const completeDropbBearMintCallback = () => {
          setIsLoading(false);
          setAlertType('success');
          setLoadingMessage('');
          setAlertMessage(
            `Your Drop Bear${dropDownCount > 1 ? 's' : ''} has been successfully minted! Head over to OpenSea to see it!`
          );
          setShowAlert(true);
        };

        watchTransaction(transactionDetails.hash, completeDropbBearMintCallback);
      } else {
        // Show error message
        setAlertMessage(message);
        setShowAlert(true);
        setLoadingMessage('');
        setIsLoading(false);
      }
    }
  };

  const handleClickWallet = async () => {
    const { result, message } = await connectMetamask();
    console.log('--- result');
    console.log(result);
    console.log('--- message');
    console.log(message);

    if (!result) {
      setAlertType('warning');
    } else {
      setAlertType('success');
    }
    setAlertMessage(message);
    setShowAlert(true);
  };

  const handleCloseAlert = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowAlert(false);
  };


  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={showAlert}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>

      <div className="App">
        <header className="App-header">
          <div className="LandingImage-Frame">
            <img src={landing} className="App-logo" alt="logo" />
          </div>
          
          <div className="MintTitle-section">
            <h1 className="Title">Drop Bears Minting</h1>
            <p className="WalletText">
              {!!userWalletAddress ? (
                <>
                  <span className="walletText">
                    Wallet:
                  </span>{' '}
                  {userWalletAddress}
                </>
              ) : (
                'No wallet found'
              )}
            </p>


            {!!userWalletAddress ? (
              <>
                <h3 className="MintHowManyTitle">How many would you like to mint?</h3>

                <div className="Mint-section">

                  <div>
                    {!IS_SOLD_OUT ? (
                      <FormControl variant="outlined" size="small">
                        <Select
                          disabled={!userWalletAddress || isLoading}
                          value={dropDownCount}
                          onChange={handleChange}
                        >
                          <MenuItem value={1}>Mint 1</MenuItem>
                          <MenuItem value={2}>Mint 2</MenuItem>
                          <MenuItem value={3}>Mint 3</MenuItem>
                          <MenuItem value={4}>Mint 4</MenuItem>
                          <MenuItem value={5}>Mint 5</MenuItem>
                          <MenuItem value={6}>Mint 6</MenuItem>
                          <MenuItem value={7}>Mint 7</MenuItem>
                          <MenuItem value={8}>Mint 8</MenuItem>
                          <MenuItem value={9}>Mint 9</MenuItem>
                          <MenuItem value={10}>Mint 10</MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      <FormControl variant="outlined" size="small">
                        <Select
                          disabled={true}
                          value={0}
                        >
                          <MenuItem value={0}>Sold Out</MenuItem>
                        </Select>
                      </FormControl>

                    )}

                    {IS_SOLD_OUT ? (
                      <Button
                        disabled={true}
                        className="Mintbox"
                        variant="contained"
                        onClick={() => {}}
                        size="large">
                        GO!
                      </Button>
                    ) : (
                      <Button
                        disabled={!userWalletAddress || isLoading}
                        className="Mintbox"
                        variant="contained"
                        onClick={handleMintClick}
                        size="large">
                        {isLoading && <LoadingElement />} GO!
                      </Button>
                    )}
                  </div>
                </div>

                {!!loadingMessage && <p className="LoadingText">{loadingMessage}</p>}
              </>

            ) : (
              <>
                <h3 className="MintHowManyTitle">First connect MetaMask</h3>

                <div className="Mint-section">

                  <div>
                    <Button
                      className="Mintbox"
                      variant="contained"
                      size="large"
                      onClick={handleClickWallet}
                      disabled={isLoading}
                    >
                      {isLoading && <LoadingElement />} Connect MetaMask
                    </Button>
                  </div>
                </div>
              </>
            )}

            <div className="Subtitle-section">
              <div className="Subtitle-container">
                <div className="text-block Supply-text">Total Supply: 10,000</div>
              </div>
              <div className="Subtitle-container">
                <div className="text-block Price-text">Price:&nbsp;0.06 ETH</div>
              </div>
            </div>
          </div>
        </header>

        <div className="Footer-container">
          <div className="Footer-section">
            <img
              alt=""
              src={"https://global-uploads.webflow.com/611c45a1d62fd12728becbd2/611f1d4156f2c3a0cb80c2b5_Drop%20Bears%20Io%20PTY%20LTD.png"}
              className="image-18"/>
          </div>

          <div className="Footer-section Text-center">
            <img
              alt=""
              src={"https://global-uploads.webflow.com/611c45a1d62fd12728becbd2/611f1cdef6dbed6d50b2cf2a_white_dropbears_M.png"}
              className="image-dropbears-logo"/>
          </div>

          <div className="Footer-section Text-right">
            <a rel="noreferrer" href="https://twitter.com/DropBearsio" target="_blank" className="social-link w-inline-block">
              <img
                alt=""
                src={"https://global-uploads.webflow.com/611c45a1d62fd12728becbd2/611c45a1d62fd14b04becc11_gorjeo%20(1).svg"}
                className="social-icon"/>
            </a>
            <a rel="noreferrer" href="https://discord.gg/PeC8VUNFgp" target="_blank" className="social-link w-inline-block">
              <img
                alt=""
                src={"https://global-uploads.webflow.com/611c45a1d62fd12728becbd2/612439a48640ce6a5d8cb623_Discord-Logo-White.svg"}
                width="63"
                className="social-icon"/>
            </a>
          </div>
        </div>
        
        <p className="text-purchase">By purchasing a Drop Bear you will hold all commercial rights to your # Drop Bear.</p>
      </div>
    </>
  );
}

export default App;
