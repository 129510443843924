const IS_PROD =
  !!process.env.REACT_APP_APP_ENV && process.env.REACT_APP_APP_ENV === 'prod';

// Maximum mint count
export const MAX_MINT_COUNT = parseInt(
  process.env.REACT_APP_MAX_MINT_COUNT
    ? process.env.REACT_APP_MAX_MINT_COUNT
    : '10',
  10
);

// Chain ID
const TESTNET_CHAIN_ID = process.env.REACT_APP_TESTNET_CHAIN_ID
  ? process.env.REACT_APP_TESTNET_CHAIN_ID
  : '';
const PROD_CHAIN_ID = process.env.REACT_APP_PROD_CHAIN_ID
  ? process.env.REACT_APP_PROD_CHAIN_ID
  : '';
export const CHAIN_ID = IS_PROD ? PROD_CHAIN_ID : TESTNET_CHAIN_ID;

// Chain Name
const TESTNET_CHAIN_NAME = process.env.REACT_APP_TESTNET_CHAIN_NAME
  ? process.env.REACT_APP_TESTNET_CHAIN_NAME
  : '';
const PROD_CHAIN_NAME = process.env.REACT_APP_PROD_CHAIN_NAME
  ? process.env.REACT_APP_PROD_CHAIN_NAME
  : '';
export const CHAIN_NAME = IS_PROD ? PROD_CHAIN_NAME : TESTNET_CHAIN_NAME;

// Sold out flag
export const IS_SOLD_OUT = process.env
  .REACT_APP_IS_SOLD_OUT
  ? process.env.REACT_APP_IS_SOLD_OUT
  : false;

// Contract address
const REACT_APP_TESTNET_ADDRESS = process.env
  .REACT_APP_TESTNET_ADDRESS
  ? process.env.REACT_APP_TESTNET_ADDRESS
  : '';

const REACT_APP_PROD_ADDRESS = process.env
  .REACT_APP_PROD_ADDRESS
  ? process.env.REACT_APP_PROD_ADDRESS
  : '';

export const CONTRACT_ADDRESS = IS_PROD
  ? REACT_APP_PROD_ADDRESS
  : REACT_APP_TESTNET_ADDRESS;
