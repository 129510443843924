import React from 'react';
import ReactLoading from 'react-loading';

import Grid from '@material-ui/core/Grid';

export const LoadingElement = () => {
  return (
    <Grid>
      <ReactLoading
        type={'spokes'}
        color={'rgba(0, 0, 0, 0.5)'}
        height={'24px'}
        width={'24px'}
      />
    </Grid>
  );
};
